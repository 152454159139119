var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c(
      "header",
      {
        staticClass:
          "header header__gradient header__gradient--fromBottom header--heroHalf",
        style: {
          "background-image":
            "linear-gradient(rgba(9, 26, 51, 0.86), rgba(2, 105, 255, 0.3)), url(./../img/bg-browse5.jpg)",
        },
      },
      [_vm._m(0)]
    ),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c(
              "h3",
              { staticClass: "browse__section-title" },
              [
                _vm._v("Trending Charts"),
                _c(
                  "router-link",
                  {
                    staticClass: "see-all",
                    attrs: { to: { name: "Trending" } },
                  },
                  [
                    _vm._v("See All"),
                    _c("icon", {
                      staticClass: "icon-arrow-right",
                      attrs: { name: "arrow-right" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("TrendingFilterList", {
              on: { changeTrendingFilter: _vm.fetchTrendingWithFilter },
            }),
            _c(
              "div",
              { staticClass: "gridBrowser grid--scroll-lte-tablet" },
              [
                _vm.trendingBeats.length === 0
                  ? _vm._l(10, function (n) {
                      return _c("tile-panel-placeholder", { key: n })
                    })
                  : _vm._l(
                      _vm.trendingBeatsWithPromoted.slice(0, 10),
                      function (beat, index) {
                        return _c("tile-panel", {
                          key: beat.id,
                          attrs: {
                            beat: beat,
                            index: index,
                            loadedBeats: _vm.trendingBeats,
                          },
                        })
                      }
                    ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("h3", { staticClass: "browse__section-title" }, [
            _vm._v("New Uploads"),
          ]),
          _c(
            "div",
            { staticClass: "gridBrowser grid--scroll-lte-tablet" },
            [
              _vm.newBeats.length === 0
                ? _vm._l(10, function (n) {
                    return _c("tile-panel-placeholder", { key: n })
                  })
                : _vm._l(_vm.newBeats, function (beat, index) {
                    return _c("tile-panel", {
                      key: beat.id,
                      attrs: {
                        beat: beat,
                        index: index,
                        loadedBeats: _vm.newBeats,
                      },
                    })
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _vm.artistTypes.length > 0
      ? _c("section", { staticClass: "section" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "wrapper" },
              [
                _c(
                  "h3",
                  { staticClass: "browse__section-title" },
                  [
                    _vm._v("Artist Types"),
                    _c(
                      "router-link",
                      {
                        staticClass: "see-all",
                        attrs: { to: { name: "ArtistTypes" } },
                      },
                      [
                        _vm._v("See All"),
                        _c("icon", {
                          staticClass: "icon-arrow-right",
                          attrs: { name: "arrow-right" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("TileSlider", {
                  attrs: {
                    parentState: "artistTypes",
                    loadedItems: _vm.artistTypes,
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("h3", { staticClass: "browse__section-title" }, [
            _vm._v("Featured Beats"),
          ]),
          _c(
            "div",
            { staticClass: "gridBrowser grid--scroll-lte-tablet" },
            [
              _vm.featuredBeats.length === 0
                ? _vm._l(5, function (n) {
                    return _c("tile-panel-placeholder", { key: n })
                  })
                : _vm._l(_vm.featuredBeats.slice(0, 5), function (beat, index) {
                    return _c("tile-panel", {
                      key: beat.id,
                      attrs: {
                        beat: beat,
                        index: index,
                        loadedBeats: _vm.featuredBeats,
                      },
                    })
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _c(
      "section",
      {
        staticClass: "section section--tabs noScroll",
        style: {
          "background-image":
            "linear-gradient(rgba(9, 25, 49, 0.9), rgba(9, 43, 94, 0.74)), url(./../img/bg-browse4.jpg)",
        },
      },
      [
        _c("div", { staticClass: "container container--cta" }, [
          _c("div", [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedTab === 0,
                  expression: "selectedTab === 0",
                },
              ],
              staticClass: "imgBackground",
              attrs: { src: "img/mockup-site.png" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedTab === 1,
                  expression: "selectedTab === 1",
                },
              ],
              staticClass: "imgBackground",
              attrs: { src: "img/mockup-amplify-player.png" },
            }),
          ]),
          _c("div", [
            _c("div", { staticClass: "flexbox h-mb-20" }, [
              _c(
                "a",
                {
                  staticClass: "tabTitle",
                  class: { "tabTitle--active": _vm.selectedTab === 0 },
                  on: {
                    click: function ($event) {
                      _vm.selectedTab = 0
                    },
                  },
                },
                [_vm._v("Elite Status")]
              ),
              _c(
                "a",
                {
                  staticClass: "tabTitle",
                  class: { "tabTitle--active": _vm.selectedTab === 1 },
                  on: {
                    click: function ($event) {
                      _vm.selectedTab = 1
                    },
                  },
                },
                [_vm._v("Amplify Player")]
              ),
            ]),
            _c("div", { staticClass: "tabTextWrapper" }, [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedTab === 0,
                      expression: "selectedTab === 0",
                    },
                  ],
                  staticClass: "p",
                },
                [
                  _vm._v(
                    "TheCharts Elite members receive an all-access pass to every feature and resource available from TheCharts. "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Everything from premium profile optimization tools, an optional password protected, triple encrypted beat vault, automated instant payments, and a full library of in-depth marketing videos to empower you with all the tools needed to reach new heights in your music career. "
                  ),
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedTab === 1,
                      expression: "selectedTab === 1",
                    },
                  ],
                  staticClass: "p",
                },
                [
                  _vm._v(
                    "More than your average embedded player, our Amplify Player is a dynamic tool for all of your beat-commerce needs. "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Easily transform any WIX, SquareSpace, WordPress, or custom HTML page into a powerful arm of your beat selling business. "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Amplify your voice. Amplify your brand. Amplify your business with your very own Amplify Player powered by TheCharts.com"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c("h3", { staticClass: "browse__section-title" }, [
              _vm._v("Producers"),
            ]),
            _vm.topProducers.length === 0
              ? _c("TileSliderPlaceholder", {
                  attrs: { "parent-state": "topProducers" },
                })
              : _c("TileSlider", {
                  attrs: {
                    "parent-state": "topProducers",
                    "loaded-items": _vm.topProducers,
                  },
                }),
          ],
          1
        ),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "h3",
            { staticClass: "browse__section-title" },
            [
              _vm._v(_vm._s(_vm.randomArtistType.name) + " Type Beats"),
              _c(
                "router-link",
                {
                  staticClass: "see-all",
                  attrs: {
                    to: {
                      name: "ArtistType",
                      params: { slug: _vm.randomArtistType.slug },
                    },
                  },
                },
                [
                  _vm._v("See All"),
                  _c("icon", {
                    staticClass: "icon-arrow-right",
                    attrs: { name: "arrow-right" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "gridBrowser grid--scroll-lte-tablet" },
            [
              _vm.randomArtistTypeBeats.length === 0
                ? _vm._l(5, function (n) {
                    return _c("tile-panel-placeholder", { key: n })
                  })
                : _vm._l(_vm.randomArtistTypeBeats, function (beat, index) {
                    return _c("tile-panel", {
                      key: beat.id,
                      attrs: {
                        beat: beat,
                        index: index,
                        loadedBeats: _vm.randomArtistTypeBeats,
                      },
                    })
                  }),
            ],
            2
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "wrapper" },
          [
            _c(
              "h3",
              { staticClass: "browse__section-title" },
              [
                _vm._v("Playlists"),
                _c(
                  "router-link",
                  {
                    staticClass: "see-all",
                    attrs: { to: { name: "Playlists" } },
                  },
                  [
                    _vm._v("See All"),
                    _c("icon", {
                      staticClass: "icon-arrow-right",
                      attrs: { name: "arrow-right" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.playlists.length === 0
              ? _c("TileSliderPlaceholder", {
                  attrs: { "parent-state": "playlists" },
                })
              : _c("TileSlider", {
                  attrs: {
                    "parent-state": "playlists",
                    "loaded-items": _vm.playlists,
                  },
                }),
          ],
          1
        ),
      ]),
    ]),
    _c("section", { staticClass: "section section--darker" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "h2 h-textCenter" }, [
          _vm._v("Beatcommerce...Simplified. "),
        ]),
        _c("div", { staticClass: "h-mt-70" }),
        _c("div", { staticClass: "grid grid__col3" }, [
          _c(
            "div",
            { staticClass: "boxIllustration boxIllustration--1" },
            [
              _vm._m(1),
              _c("icon", {
                staticClass: "boxIllustration__icon",
                attrs: { name: "browse-music" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "boxIllustration boxIllustration--2" },
            [
              _vm._m(2),
              _c("icon", {
                staticClass: "boxIllustration__icon",
                attrs: { name: "browse-purchase" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "boxIllustration boxIllustration--3" },
            [
              _vm._m(3),
              _c("icon", {
                staticClass: "boxIllustration__icon",
                attrs: { name: "browse-produce" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container container--sm" }, [
        _c("h2", { staticClass: "h2 h-textCenter" }, [
          _vm._v("Frequently Asked Questions"),
        ]),
        _c(
          "div",
          { staticClass: "accordion" },
          _vm._l(_vm.faqQuestions, function (question, index) {
            return _c(
              "div",
              {
                staticClass: "accordion__item",
                class: { "accordion__item--open": question.isActive },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "accordion__title",
                    on: {
                      click: function ($event) {
                        return _vm.toggleAccordion(index)
                      },
                    },
                  },
                  [
                    _vm._v(_vm._s(question.title)),
                    _c("icon", {
                      staticClass: "accordion__icon",
                      attrs: {
                        name: !question.isActive ? "open-menu" : "arrow-down",
                      },
                    }),
                  ],
                  1
                ),
                _c("transition", { attrs: { name: "accordionAnimation" } }, [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: question.isActive,
                        expression: "question.isActive",
                      },
                    ],
                    staticClass: "accordion__content",
                    domProps: { innerHTML: _vm._s(question.content) },
                  }),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "halfWidthWrapper" }, [
        _c("h1", { staticClass: "h1" }, [
          _vm._v("Your New Favorite Place To Buy And Sell Beats."),
        ]),
        _c("p", { staticClass: "p textSizeLg" }, [
          _vm._v(
            "Industry quality, radio ready beats and instrumentals that will take any artist to the next level. Together we’ll give them music to talk about."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxIllustration__text" }, [
      _c("h3", { staticClass: "h3" }, [_vm._v("Play")]),
      _c("p", [
        _vm._v(
          "Listen to thousands of beats from our worldwide community of elite producers and beatmakers."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxIllustration__text" }, [
      _c("h3", { staticClass: "h3" }, [_vm._v("Purchase")]),
      _c("p", [
        _vm._v(
          "Select your license, complete payment, and receive your beats instantly!"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxIllustration__text" }, [
      _c("h3", { staticClass: "h3" }, [_vm._v("Produce")]),
      _c("p", [
        _vm._v(
          "Use the instrumentals you purchase to produce your next hit record. Give them music to talk about."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }