



























































































































































import { Component, Mixins } from 'vue-property-decorator'

import TileSlider from '@/components/TileSlider.vue'
import TilePanel from '@/components/TilePanel.vue'
import TilePanelPlaceholder from '@/components/TilePanelPlaceholder.vue'
import TileSliderPlaceholder from '@/components/TileSliderPlaceholder.vue'
import TrendingFilterList from '@/components/TrendingFilterList.vue'

import faqQuestions from '~/const/faq'

import { fetchTopProducers } from '@/api/producers'
import { fetchTrendingBeats, TrendingPeriod, fetchRandomBeats } from '@/api/beats'
import { BeatFilterType, fetchBeats } from '~/api/beats'
import { UserStore } from '@/store/user'
import { fetchAllArtistTypes } from '@/api/artist-type'
import { RandomArtistType } from '@/mixins/random-artist-type'
import { fetchAllGenres } from '@/api/genre'
import { fetchPromotedBeats } from '@/api/promotion'
import { fetchPlaylistsRandom } from '@/api/playlists'

@Component({ components: { TileSlider, TilePanel, TilePanelPlaceholder, TileSliderPlaceholder, TrendingFilterList } })
export default class BrowsePage extends Mixins(RandomArtistType) {
    topProducers: IProducer[] = []
    artistTypes: IArtistType[] = []
    newBeats: IBeat[] = []
    randomArtistTypeBeats: IBeat[] = []
    randomBeats: IBeat[] = []
    trendingBeats: IBeat[] = []
    playlists: IPlaylist[] = []
    promotedBeatsTrending: IBeat[] = []
    promotedBeatsHomepage: IBeat[] = []
    faqQuestions = faqQuestions
    selectedTab = 0

    @UserStore.State('followedProducers') followedProducers: IFollowedProducer[]

    get trendingBeatsWithPromoted() {
        return [...this.promotedBeatsTrending, ...this.trendingBeats]
    }

    get featuredBeats() {
        return [...this.promotedBeatsHomepage, ...this.randomBeats]
    }

    toggleAccordion(index: number) {
        const currentOpenedQuestion = this.faqQuestions[index]
        this.faqQuestions.forEach(question => {
            // if the click is on the current opened question
            // exit forEach and execute other code to toggle it (in this case it will close it)
            if (question === currentOpenedQuestion) return true
            // if the click is on a closed question, close the current active question
            // and execute other code to toggle clicked question (in this case it will open it)
            else if (question.isActive === true) question.isActive = !question.isActive
        })
        this.faqQuestions[index].isActive = !this.faqQuestions[index].isActive
    }

    async fetchTrendingWithFilter(limit: number, trendingFilterPeriod: TrendingPeriod, trendingFilterGenreId: IGenre['id']) {
        this.trendingBeats = await fetchTrendingBeats(limit, trendingFilterPeriod, trendingFilterGenreId)
    }

    async mounted() {
        this.trendingBeats = await fetchTrendingBeats(10, TrendingPeriod.Week, null)
        this.promotedBeatsTrending = await fetchPromotedBeats('Trending')
        this.newBeats = await fetchBeats({ filterType: BeatFilterType.NewUploads })
        this.artistTypes = await fetchAllArtistTypes(true)
        this.promotedBeatsHomepage = await fetchPromotedBeats('Homepage')
        this.randomBeats = await fetchRandomBeats()
        this.randomArtistTypeBeats = await fetchBeats({ filterType: BeatFilterType.ArtistType, slug: this.randomArtistType.slug, limit: 5 })
        this.topProducers = await fetchTopProducers()
        this.playlists = await fetchPlaylistsRandom()
    }
}
